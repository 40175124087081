import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import sellXAUService from "./sellXAUService";
import Cookies from "js-cookie";

// get user
const encodeString = Cookies.get("user");
const decodedString = encodeString ? decodeURIComponent(encodeString) : null;
const user = encodeString ? JSON.parse(decodedString) : null;

const initialState = {
  user: user ? user : null,
  sellXAU: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// create sell
export const createSell = createAsyncThunk("sell/create", async (sellXAUData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.data.user.user_jwt_token;
    console.log(token);
    return await sellXAUService.createSell(sellXAUData, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    if (error.response.status === 401) {
      Cookies.set("user", JSON.stringify(error.response.data), { expires: 2 });
    }

    return thunkAPI.rejectWithValue(message);
  }
});

export const sellXAUSlice = createSlice({
  name: "sellXAU",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSell.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSell.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = state.isSuccess === false ? true : false;
        state.isSuccess = action.payload.success;
        state.message = action.payload.msg;
        state.user = action.payload;
        state.sellXAU = action.payload;
      })
      .addCase(createSell.rejected, (state, action) => {
        const encodeString = Cookies.get("user");
        const decodedString = encodeString ? decodeURIComponent(encodeString) : null;
        const user = encodeString ? JSON.parse(decodedString) : null;

        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.msg;
        state.user = user;
        state.sellXAU = action.payload;
      });
  },
});

export const { reset } = sellXAUSlice.actions;
export default sellXAUSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import kycService from "./kycService";
import Cookies from "js-cookie";

// get user
const encodeString = Cookies.get("user");
const decodedString = encodeString ? decodeURIComponent(encodeString) : null;
const user = encodeString ? JSON.parse(decodedString) : null;

const initialState = {
  user: user ? user : null,
  createKYC: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// create createKYC
export const createKYC = createAsyncThunk("createKYC/create", async (createKYCData, thunkAPI) => {
  try {
    // const token = thunkAPI.getState().auth.user.data.orang.user_jwt_token;
    // const token = thunkAPI.getState().auth.user.data.user.user_jwt_token;
    const token = thunkAPI.getState().auth.token;
    console.log(createKYCData, token);
    return await kycService.createKYC(createKYCData, token);
  } catch (error) {
    // const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const message = error.response?.data?.msg || error.message || error.toString();
    return thunkAPI.rejectWithValue({ success: false, msg: message });
  }
});

export const createKYCSlice = createSlice({
  name: "createKYC",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createKYC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createKYC.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = state.isSuccess === false ? true : false;
        state.isSuccess = action.payload.success;
        state.message = action.payload && action.payload.msg;
        state.user = action.payload;
        state.createKYC = action.payload;
      })
      .addCase(createKYC.rejected, (state, action) => {
        const encodeString = Cookies.get("user");
        const decodedString = encodeString ? decodeURIComponent(encodeString) : null;
        const user = encodeString ? JSON.parse(decodedString) : null;

        state.isLoading = false;
        state.isError = true;
        state.message = action.payload && action.payload.msg;
        state.user = user;
        state.createKYC = action.payload;
      });
  },
});

export const { reset } = createKYCSlice.actions;
export default createKYCSlice.reducer;

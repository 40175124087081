import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import Nav from "../components/Nav";
import NotLogin from "../components/auth/NotLogin";
import PriceGold from "../components/PriceGold";
import TotalBalance from "../components/TotalBalance";
import TotalAssets from "../components/TotalAssets";
import RecentTransactions from "../components/RecentTransactions";
import ChartGoldPrice from "../components/charts/ChartGoldPrice";
import { getProfile } from "../features/profile/profileSlice";
import { getBalance } from "../features/balance/idr/balanceIDRSlice";
import statusProfile from "../features/profile/statusProfile";
import ModalSession from "../components/utilities/modal/ModalSession";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import { useState } from "react";

function Dashboard() {
  const [showModalSession, setShowModalSession] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Xaurius | Dashboard";
    dispatch(getProfile());
    dispatch(getBalance());

    const statusLogin = async () => {
      try {
        await statusProfile();
      } catch (err) {
        console.log(err.response.data.success);
        setShowModalSession(true);
      }
    };

    statusLogin();
  }, [user, dispatch, profile.msg, profile.success]);

  return (
    <>
      {user && user.success ? (
        <>
          <Nav />
          <div className="md:pl-[30vw] xl:pl-[20vw]">
            <div className="px-6 pb-32">
              <div className="kyc pt-6 flex justify-between items-center">
                <div>
                  {profile.data?.full_name != null ? (
                    <h3>
                      <strong>{user ? profile.data?.full_name : null}</strong>
                    </h3>
                  ) : (
                    <h3>
                      <strong>no-name</strong>
                    </h3>
                  )}

                  <span className="text-[#444D56]">
                    Selamat Datang di Dashboard Xaurius
                  </span>
                </div>
                <div className="relative overflow-hidden w-12 h-12 rounded-full border border-gray-300">
                  <img
                    src={profile.data?.image_url}
                    alt=""
                    className="absolute w-full h-full object-cover"
                  />
                </div>
                {/* <span>In order to use this application, you need to complete the KYC compliance. Please complete your data.</span> */}
              </div>

              <Swiper
                spaceBetween={16}
                slidesPerView={1}
                onSlideChange={(v) => console.log(v)}
                onSwiper={(swiper) => console.log(swiper)}
                breakpoints={{
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 1,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 2,
                  },
                  // // when window width is >= 1291px
                  // 1291: {
                  //   slidesPerView: 3,
                  // },
                }}
                pagination={{ el: ".swiper-pagination", type: "bullets" }}
              >
                <SwiperSlide>
                  <div className="py-6">
                    <TotalBalance></TotalBalance>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="py-6">
                    <PriceGold></PriceGold>
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                  <div className="py-6">
                    <TotalAssets></TotalAssets>
                  </div>
                </SwiperSlide> */}
              </Swiper>

              <div className="w-full flex xl:hidden justify-center items-center gap-2">
                <div className="w-3 h-3 bg-gold2 rounded-full -mt-7 mb-8"></div>
                <div className="w-3 h-3 bg-gold2 rounded-full -mt-7 mb-8"></div>
                <div className="w-3 h-3 bg-gold2 rounded-full -mt-7 mb-8"></div>
              </div>

              <ChartGoldPrice />

              <RecentTransactions />
            </div>
          </div>
        </>
      ) : (
        <>
          <NotLogin />
        </>
      )}

      {showModalSession && <ModalSession />}
    </>
  );
}

export default Dashboard;

import axios from "axios";
import Cookies from "js-cookie";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL =
  process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

// register user
const register = async (userData) => {
  const response = await axios.post(API_URL + "auth/register", userData);
  return response.data;
};

// email verification
const emailVerification = async (verificationData) => {
  const response = await axios.post(
    API_URL + "auth/email-verification",
    verificationData
  );
  const isSuccess = response.data.success;

  console.log({ isSuccess });

  if (isSuccess) {
    // Ubah key "orang" menjadi "user"
    if (response.data.data && response.data.data.orang) {
      response.data.data.user = response.data.data.orang;
      delete response.data.data.orang;
    }

    // Simpan response.data ke Cookies setelah perubahan key
    Cookies.set("user", JSON.stringify(response.data), { expires: 2 });
    Cookies.set("jwt", response.data.data.user.user_jwt_token, { expires: 2 });
    window.location.href = `/auth-success/${verificationData.email}`;
  }

  return response.data;
};

// login user
const login = async (userData) => {
  const response = await axios.post(API_URL + "auth/login", userData);
  const isSuccess = response.data.success;
  console.log(response.data);

  if (isSuccess) {
    const email = userData.email;
    const simpan = { ...response.data, email };
    Cookies.set("user", JSON.stringify(simpan), { expires: 2 });
    Cookies.set("jwt", response.data.data.user.user_jwt_token, { expires: 2 });
    window.location.href = "/dashboard";
  }

  return response.data;
};

// forgot password user
export const forgotPassword = async (email) => {
  const response = await axios.post(API_URL + "auth/forgot-password", email);

  return response.data;
};

// logout user
export const logout = async () => {
  Cookies.remove("user");
  Cookies.remove("jwt");
  window.location.href = "/";
};

const authService = {
  register,
  emailVerification,
  login,
  forgotPassword,
  logout,
};

export default authService;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL = process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

export const fetchNetworks = createAsyncThunk('evm-tokens', async () => {
    try {
      const response = await axios.get(API_URL + 'evm-tokens');
      return response.data;
    } catch (error) {
      throw Error(error.message);
    }
  });

  const networksSlice = createSlice({
    name: 'networks',
    initialState: {
      networks: [],
      status: 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchNetworks.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchNetworks.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.networks = action.payload.data;
        })
        .addCase(fetchNetworks.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });

  export const selectAllNetworks = (state) => state.networks.networks;
  export const selectNetworksStatus = (state) => state.networks.status;

  export default networksSlice.reducer;

import React from "react";
import { useSelector } from "react-redux";
import BlogCard from "./BlogCard";

function BlogContent() {
  const articles = useSelector((state) => state.blog.displayedArticles);

  return (
    <>
      <div className="flex flex-wrap w-full justify-center gap-x-8 gap-y-16">
        {articles.map((item) => (
          <BlogCard article={{ ...item }} key={item.id} />
        ))}
      </div>
    </>
  );
}

export default BlogContent;

import React from "react";
import imageXauriusGold from "../../assets/images/xaurius-gold.png";

function WhatIsXaurius() {
  return (
    <>
      <div className="h-full lg:h-[512px] mb-14 md:mb-24">
        <div className="lg:flex px-4 md:px-8 lg:px-20">
          <div className="basis-2/4">
            <div className="h-full px-8 py-2 lg:py-12">
              <h2 className="text-xl md:text-2xl font-semibold mb-4">Apa itu XAURIUS?</h2>
              <p className="md:text-lg mb-4 md:mb-8">XAURIUS adalah perusahaan aset digital era modern yang menciptakan mata uang kripto (cryptocurrency) yang didukung emas, yaitu Token XAU.</p>
              <p className="md:text-lg mb-4 md:mb-8">Xaurius dicipatakan untuk mengubah industri emas dengan menciptakan infrastruktur berbasis rantai-blok (blockchain) sembari memungkinkan tokenisasi emas menjadi Token XAU, serta memastikan pembelian dan penjualan emas yang mulus, transaksi yang aman, dan transparan.</p>
            </div>
          </div>
          <div className="basis-2/4">
            <div className="h-full py-2 px-8 lg:px-16">
              <img src={imageXauriusGold} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatIsXaurius;

import axios from "axios";
import Cookies from "js-cookie";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL = process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

// get user profile
const statusProfile = async () => {
  const token = Cookies.get("jwt");
  const config = {
    headers: {
      JWT: token,
    },
  };

  const response = await axios.get(API_URL + "profiles/detail", config);
  return response.data;
};


export default statusProfile;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import Nav from "../../components/Nav";
import NotLogin from "../../components/auth/NotLogin";
import PriceGold from "../../components/PriceGold";
import TotalBalance from "../../components/TotalBalance";
// import TotalAssets from "../../components/TotalAssets";
import FormWithdrawIDR from "../../components/forms/FormWithdrawIDR";
import BankAccount from "../../components/BankAccount";
import { getProfile } from "../../features/profile/profileSlice";
import Review from "../../assets/images/review.gif";

import "swiper/css";
import { BreadcrumbGoBack } from "../../components/breadcrumb/Breadcrumb";
import RecentHistory from "../history/Recenthistory";

function WithdrawIDR() {
  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);
  const { message } = useSelector((state) => state.balanceIDR);
  const { bankUser, isError } = useSelector((state) => state.bankUser);
  const bankData = bankUser && bankUser[0];

  const messageStatusKYC = message;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile()); // check kyc status
    // console.log(bankData);
  }, [user, isError, dispatch]);

  return (
    <>
      {user && user.success ? (
        <>
          {profile.data && profile.data.status_kyc === "approved" ? (
            <>
              <Nav />
              <div className="md:pl-[30vw] xl:pl-[20vw]">
                <div className="pt-6 pr-6 pb-[8rem] pl-6">
                  <div className="kyc">
                    {/* <h3>
                      Hello<strong>username@gmail.com</strong>
                    </h3> */}
                    {/* <span>In order to use this application, you need to complete the KYC compliance. Please complete your data.</span> */}
                  </div>

                  <Swiper
                    spaceBetween={16}
                    slidesPerView={1}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    breakpoints={{
                      // when window width is >= 640px
                      640: {
                        slidesPerView: 1,
                      },
                      // when window width is >= 768px
                      768: {
                        slidesPerView: 2,
                      },
                      // when window width is >= 1291px
                      // 1291: {
                      //   slidesPerView: 3,
                      // },
                    }}
                  >
                    <SwiperSlide>
                      <div className="py-6">
                        <TotalBalance></TotalBalance>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="py-6">
                        <PriceGold></PriceGold>
                      </div>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                      <div className="py-6">
                        <TotalAssets></TotalAssets>
                      </div>
                    </SwiperSlide> */}
                  </Swiper>

                  <div className="w-full flex xl:hidden justify-center items-center gap-2">
                    <div className="w-3 h-3 bg-gold2 rounded-full -mt-7 mb-8"></div>
                    <div className="w-3 h-3 bg-gold2 rounded-full -mt-7 mb-8"></div>
                    <div className="w-3 h-3 bg-gold2 rounded-full -mt-7 mb-8"></div>
                  </div>

                  {bankData ? <FormWithdrawIDR /> : <BankAccount />}

                  <RecentHistory histories={"withdraws"} />
                </div>
              </div>
            </>
          ) : (
            <>
              <BreadcrumbGoBack />
              <div className="flex flex-col justify-center md:items-center md:w-2/4 xl:w-2/6 mx-auto h-screen">
                <div className="flex flex-col items-center px-8 mb-3">
                  <img src={Review} alt="" />
                  <p>{messageStatusKYC}.</p>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <NotLogin />
        </>
      )}
    </>
  );
}

export default WithdrawIDR;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { pagination } from "../../features/blog/blogSlice";
import { Pagination } from "@mui/material";
import { FiArrowLeft } from "react-icons/fi";
import { FiArrowRight } from "react-icons/fi";

function BlogPagination() {
  const page = useSelector((state) => state.blog.page);
  const maxPage = useSelector((state) => state.blog.maxPage);
  const dispatch = useDispatch();

  const handlePagination = (page) => {
    dispatch(pagination(page));
  };

  const handleChange = (_, value) => {
    dispatch(pagination(value));
  };

  return (
    <>
      <div className="flex justify-between pt-6">
        <button
          onClick={() => {
            page > 1 && handlePagination(page - 1);
          }}
          className={
            page > 1
              ? "flex items-center px-3 py-1.5 gap-2 border-[1.5px] rounded-lg text-sm font-medium transition duration-300 border-gray-300 bg-white text-gray-700 hover:bg-[#f9fafb]"
              : "flex items-center px-3 py-1.5 gap-2 border-[1.5px] rounded-lg text-sm font-medium cursor-default border-gray-300 bg-gray-200 text-gray-700"
          }
        >
          <FiArrowLeft size={"1.3rem"} />
          <span>Previous</span>
        </button>

        <Pagination
          onChange={handleChange}
          count={maxPage}
          page={page}
          defaultPage={page}
          hideNextButton
          hidePrevButton
          size="large"
          className="custom-pagination"
        />

        <button
          onClick={() => {
            page < maxPage && handlePagination(page + 1);
          }}
          className={
            page < maxPage
              ? "flex items-center px-3 py-1.5 gap-2 border-[1.5px] rounded-lg text-sm font-medium transition duration-300 border-gray-300 bg-white text-gray-700 hover:bg-[#f9fafb]"
              : "flex items-center px-3 py-1.5 gap-2 border-[1.5px] rounded-lg text-sm font-medium cursor-default border-gray-300 bg-gray-200 text-gray-700"
          }
        >
          <span>Next</span>
          <FiArrowRight size={"1.3rem"} />
        </button>
      </div>
    </>
  );
}

export default BlogPagination;

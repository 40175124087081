import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import chart from "../../data/GoldPrice.json";
import { getChart } from "../../features/chart/chartSlice";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
} from "recharts";

function ChartGoldPrice() {
  const { chart } = useSelector((state) => state.chart);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getChart());
  }, [dispatch]);

  if (!chart || !chart.data || !chart.data.datas) {
    return <div>Loading...</div>;
  }

  // Olah data menjadi format yang siap digunakan
  const chartData = chart.data.datas.map((item) => ({
    name: item.cdatestr, // Label sumbu X
    uv: parseFloat(item.cclose), // Nilai sumbu Y
  }));

  console.log(chartData);

  return (
    <div className="rounded-xl shadow-lg p-4 mb-12 h-full">
      <h1 className="mt-4 mb-2">Gold Price Chart</h1>
      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <AreaChart
            data={chartData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" style={{ fontSize: "12px" }} />
            <YAxis style={{ fontSize: "12px" }} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#F9CE0A"
              fill="#FAF1CB"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default ChartGoldPrice;

import React from "react";
import { MdOutlineArrowOutward } from "react-icons/md";

function BlogCard({ article }) {
  console.log("article: ", article)
  return (
    <>
      <div className="flex flex-col max-w-md gap-7">
        <div
          className="flex items-end w-full h-[17.5rem] bg-cover bg-center"
          style={{ backgroundImage: `url(${article.img})` }}
        >
          <div className="flex justify-between w-full p-6 border-t-[1px] backdrop-blur-md border-white/50 bg-white/30">
            <div>
              <h4 className="text-sm font-semibold text-white">
                {article.writer}
              </h4>
              <h4 className="text-sm text-white">{article.date}</h4>
            </div>

            <h4 className="text-sm font-semibold text-white">
              {article.category}
            </h4>
          </div>
        </div>

        <div className="h-[150px]">
          <h2 className="mb-3 text-2xl font-semibold">{article.title}</h2>
          <p className="text-gray-500">{article.body}</p>
        </div>

        <div className="flex items-center gap-2">
          <a
            href={article.link}
            target="_blank"
            className="font-medium transition duration-300 text-gold1 hover:text-[#6941c6]"
            rel="noreferrer"
          >
            Read Post
          </a>
          <MdOutlineArrowOutward color="#AE8625" size={"1.3rem"} />
        </div>
      </div>
    </>
  );
}

export default BlogCard;

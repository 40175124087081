import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import depositXAUService from "./depositXAUService";
import Cookies from "js-cookie";

const initialState = {
  message: "",
  depositXAU: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
};

// get address
export const getAddressDeposit = createAsyncThunk("ccdeposits/get-address", async (_, thunkAPI) => {
  // console.log(thunkAPI);
  try {
    const token = thunkAPI.getState().auth.user.data.user.user_jwt_token;
    // console.log(token);
    return await depositXAUService.getAddressDeposit(token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    if (error.response.status === 401) {
      Cookies.set("user", JSON.stringify(error.response.data), { expires: 2 });
    }

    return thunkAPI.rejectWithValue(message);
  }
});

export const depositXAUSlice = createSlice({
  name: "depositXAU",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddressDeposit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAddressDeposit.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = state.isSuccess === false ? true : false;
        state.isSuccess = action.payload.success;
        state.message = action.payload && action.payload.msg;
        state.depositXAU = action.payload;
      })
      .addCase(getAddressDeposit.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload && action.payload.msg;
      });
  },
});

export const { reset } = depositXAUSlice.actions;
export default depositXAUSlice.reducer;

import React from "react";

function BlogHeader() {
  return (
    <>
      <div className="px-4 md:px-8 py-10 md:py-24 bg-[#f9fafb]">
        <div>
          <h1 className="text-2xl md:text-5xl font-semibold mb-2 md:mb-6">
            Xaurius blog
          </h1>
          <p className="md:text-lg mb-4 md:mb-8">
            Tool and strategies modern teams need to help their companies grow.
          </p>
        </div>

        <div>
          <form>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email"
              className="w-40 h-9 md:w-96 md:h-auto mr-3.5 mb-1 rounded-md focus:placeholder:text-black border-slate-300"
            />
            <button
              type="submit"
              className="px-3 py-1.5 md:px-5 md:py-2.5 rounded-lg text-sm md:text-base transition duration-300 bg-[#FCCF08] hover:bg-[#6941c6]"
            >
              Subscribe
            </button>
          </form>
          <p className="text-xs md:text-sm md:mt-1">
            We care about your data in our
            <a href="#" className="underline underline-offset-2">
              {" "}
              privacy policy
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default BlogHeader;

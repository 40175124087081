import React from "react";
import { NavLink } from "react-router-dom";
import { blogArticles, categories } from "../../data/blogArticles";
import Button from "../utilities/button";
import { FiArrowRight } from "react-icons/fi";
import { MdOutlineArrowOutward } from "react-icons/md";

const articles = blogArticles.slice(0, 3);

function Blog() {
  return (
    <>
      <div className="flex flex-col items-center gap-8 px-4 md:px-10 py-20 bg-white">
        <div className="text-center">
          <h2 className="text-2xl font-semibold">Xaurius blog</h2>
          <p className="text-gray-500 text-center">
            Interviews, tips, guides, industry best practices, and news.
          </p>
        </div>

        <div className="flex flex-wrap lg:flex-nowrap justify-center lg:justify-normal gap-6">
          {articles.map((article) => {
            const colorClass =
              categories[article.category] || "text-[#000000] bg-[#000000]/10";

            return (
              <div key={article.id} className="flex flex-col gap-7 max-w-sm">
                <div className="aspect-video overflow-hidden">
                  <img
                    src={article.img}
                    alt=""
                    className="object-cover w-full h-full"
                  />
                </div>

                <div className="flex flex-col gap-1.5 h-[190px]">
                  <span className="text-sm font-medium text-[#6841c5]">
                    {article.writer} &bull; {article.date}
                  </span>
                  <a
                    href={article.link}
                    target="_blank"
                    rel="noreferrer"
                    className="flex justify-between items-center"
                  >
                    <h3 className="w-11/12 text-lg font-semibold">
                      {article.title}
                    </h3>
                    <MdOutlineArrowOutward size={"1.5rem"} />
                  </a>
                  <p className="text-gray-500">{article.body}</p>
                </div>

                <div>
                  <span
                    className={`px-2.5 py-0.5 rounded-full text-sm font-medium ${colorClass}`}
                  >
                    {article.category}
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        <NavLink to={"/blog"}>
          <Button>
            <span className="flex items-center gap-2">
              View all post <FiArrowRight size="1.3rem" />
            </span>
          </Button>
        </NavLink>
      </div>
    </>
  );
}

export default Blog;

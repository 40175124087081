import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { HiChevronRight } from "react-icons/hi2";
import Nav from "../../components/Nav";
import NotLogin from "../../components/auth/NotLogin";
import { BreadcrumbGoBack } from "../../components/breadcrumb/Breadcrumb";
import FormKYC1 from "../../components/forms/FormKYC1";
import { createKYC, reset } from "../../features/kyc/kycSlice";

function KYC() {
  const { user } = useSelector((state) => state.auth);

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(createKYC());
  // }, [user, dispatch]);

  return (
    <>
      {user && user.success ? (
        <>
          {/* <Nav /> */}
          <div className="md:px-[20vw] xl:px-[30vw]">
            {/* <BreadcrumbGoBack title={"Home"} /> */}
            <FormKYC1 />
          </div>
        </>
      ) : (
        <>
          <NotLogin />
        </>
      )}
    </>
  );
}

export default KYC;

import axios from "axios";
import Cookies from "js-cookie";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL = process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;
const token = Cookies.get('jwt');

// kyc user
const createKYC = (kycData) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      JWT: token,
    },
  };

  // Menghilangkan kata kunci async dan mengebalikan hasil langsung
  return axios.post(API_URL + "profiles/update", kycData, config)
    .then(response => response.data)
    .catch(error => {
      throw error; // Meneruskan kembali kesalahan untuk penanganan selanjutnya
    });
};

export default createKYC;

import axios from "axios";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL = process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

// kyc user
const createUpload = async (datas, token) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      JWT: token,
    },
  };

  // console.log(file, token, config);
  const response = await axios.post(API_URL + "deposits/upload-file", datas, config);

  return response.data;
};

const uploadService = {
  createUpload,
};

export default uploadService;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import Nav from "../../components/Nav";
import NotLogin from "../../components/auth/NotLogin";
import { BreadcrumbGoBack } from "../../components/breadcrumb/Breadcrumb";
import HistoryTransaction from "../../components/history/HistoryTransaction";
import { getHistory } from "../../features/history/historySlice";
import SearchImg from "../../assets/images/search-icon.png";

function History() {
  const { user } = useSelector((state) => state.auth);
  const { history, isLoading } = useSelector((state) => state.history);

  // console.log(history.data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHistory());
  }, [dispatch]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {user.success ? (
        <>
          <Nav />
          <div className="md:pl-[30vw] xl:pl-[20vw]">
            <BreadcrumbGoBack title={"Riwayat"} />
            <div className="px-6 pb-32">
              <div className="md:px-8">
                {history.data.length ? (
                  <>
                    {history.data.map((list) => {
                      let judul;
                      let total;
                      let ammountIDR;
                      let ammountXAU;
                      let jumlahIDR;
                      let jumlahXAU;
                      let txhash;
                      if (list.table === "deposits") {
                        judul = "Setoran IDR";
                        total = list.obj.total;
                        ammountIDR = "Jumlah";
                      } else if (list.table === "withdraws") {
                        judul = "Penarikan IDR";
                        total = list.obj.ammount;
                        ammountIDR = "Jumlah";
                      } else if (list.table === "withdraw_cryptos") {
                        judul = "Penarikan XAU";
                        total = list.obj.amount;
                        txhash = list.obj.txhash;
                        ammountIDR = "Jumlah";
                      } else if (list.table === "buys") {
                        judul = "Pembelian XAU";
                        total = list.obj.price;
                        jumlahIDR = list.obj.amount_idr;
                        const num = list.obj.amount_xau;
                        const fix = parseFloat(num).toFixed(1);
                        jumlahXAU = fix;
                        ammountIDR = "Jumlah IDR";
                        ammountXAU = "Jumlah XAU";
                      } else if (list.table === "sells") {
                        judul = "Penjualan XAU";
                        total = list.obj.price;
                        jumlahIDR = list.obj.amount_idr;
                        jumlahXAU = list.obj.amount_xau;
                        ammountIDR = "Jumlah IDR";
                        ammountXAU = "Jumlah XAU";
                      }

                      const getDate = new Date(list.obj.created_at);
                      const newDate = getDate
                        .toLocaleDateString("id-ID", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })
                        .replace(/ /g, " ");

                      return (
                        <HistoryTransaction
                          key={list.id}
                          date={newDate}
                          judul={judul}
                          total={total}
                          status={list.obj.status}
                          jumlahIDR={jumlahIDR}
                          jumlahXAU={jumlahXAU}
                          id={list.obj.id}
                          ammountIDR={ammountIDR}
                          ammountXAU={ammountXAU}
                          txhash={txhash}
                        />
                      );
                    })}
                  </>
                ) : (
                  <div className="w-full h-screen flex justify-center items-center">
                    <div className="flex flex-col gap-4 items-center justify-center">
                      <img src={SearchImg} alt="" width="90" />
                      <p className="text-base text-[#171302] text-center">
                        Belum ada transaksi yang <br /> tersimpan
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <NotLogin />
        </>
      )}
    </>
  );
}

export default History;

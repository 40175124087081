import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { searchArticle, filterArticle } from "../../features/blog/blogSlice";
import { categories } from "../../data/blogArticles";
// import { FiSearch } from "react-icons/ci";
import { FiSearch } from "react-icons/fi";

const navItems = Object.getOwnPropertyNames(categories);
navItems.unshift("View All");

function BlogNav() {
  const [activeTab, setActiveTab] = useState("View All");
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();
  const searchHandler = (keyword) => {
    dispatch(searchArticle(keyword));
  };
  const filterHandler = (category) => {
    dispatch(filterArticle(category));
  };

  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      searchHandler(searchTerm);
      setActiveTab("View All");
    }
  };

  return (
    <>
      <div className="w-96 hidden md:block">
        <div className="mb-7 relative">
          <FiSearch
            size={"1.3rem"}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
          />
          <input
            type="search"
            name="search"
            id="search"
            placeholder="Search"
            className="w-full pl-10 rounded-md border-slate-300"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={keyPressHandler}
          />
        </div>

        <div>
          <h4 className="mb-5 text-sm font-semibold text-gold1">
            Blog Categories
          </h4>
          <ul className="flex flex-col gap-2">
            {navItems.map((item, index) => (
              <li
                key={index}
                onClick={() => {
                  filterHandler(item);
                  setActiveTab(item);
                }}
                className={
                  activeTab === item
                    ? "px-3.5 py-3 rounded-md font-medium cursor-pointer bg-[#FCCF08]/25 text-gold1"
                    : "px-3.5 py-3 rounded-md font-medium cursor-pointer transition duration-300 hover:bg-gray-200/25 text-gray-500 hover:text-[#6941c6]"
                }
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default BlogNav;

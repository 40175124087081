import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Card from "../utilities/card";
import iconDownloadAppStore from "../../assets/images/download-app-store.png";
import iconDownloadPlayStore from "../../assets/images/download-play-store.png";
import imageApps from "../../assets/images/apps.png";

function StartJourney() {
  const animation = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.1 });

  const bottomToTop = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1 },
    },
  };

  useEffect(() => {
    if (inView) {
      animation.start("visible");
    } else {
      animation.start("hidden");
    }
  }, [animation, inView]);

  return (
    <>
      <div className="px-4 md:px-20 py-8 my-8">
        <Card>
          <div className="h-[480px] overflow-hidden relative">
            <div className="md:flex justify-center items-center px-4 h-full">
              <div className="basis-2/4 mb-8 px-8 py-8">
                <div className="h-full">
                  <h2 className="text-4xl font-semibold mb-2">Start your free trial</h2>
                  <p className="text-xl">Personal performance tracking made easy.</p>
                  <div className="flex mt-8">
                    <div className="h-[32px] mr-2">
                      <img className="h-full" src={iconDownloadAppStore} alt="Download App Store" />
                    </div>
                    <div className="h-[32px]">
                      <img className="h-full" src={iconDownloadPlayStore} alt="Download Play Store" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="basis-2/4">
                <motion.div className="h-full py-8" ref={ref} animate={animation} variants={bottomToTop}>
                  <img className="md:w-[314px] w-[50%] mx-auto md:mt-[320px]" src={imageApps} alt="Xaurius Apps" />
                </motion.div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

export default StartJourney;

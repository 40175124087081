import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BreadcrumbGoBack } from "../../components/breadcrumb/Breadcrumb";
import { login, reset } from "../../features/auth/authSlice";
import { ToastContainer, toast } from "react-toastify";
import Button from "../../components/utilities/button";
import ModalForgotPassword from "../../components/auth/ModalForgotPassword";

function Login() {
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const [messageLogin, setMessageLogin] = useState(message);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      console.log(message);
      setMessageLogin(message);
    }

    if (isSuccess) {
      setMessageLogin("");
      toast.success(message);
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch, messageLogin]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const resetInput = () => {
    setMessageLogin("");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      // fullname,
      email,
      password,
    };

    dispatch(login(userData));
  };

  const handleShowModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      <div className="absolute top-0 left-0 w-full">
        <BreadcrumbGoBack title={"Login"} />
      </div>
      <div className="flex flex-col justify-center md:items-center md:w-2/4 xl:w-2/6 mx-auto h-screen">
        <div className="flex flex-col items-center px-8 mb-3">
          <h1 className="font-medium text-2xl md:text-3xl mb-2">
            Selamat datang di{" "}
            <span className="font-semibold text-[#FCCF08]">Xaurius</span>
          </h1>
          <p className="text-center text-[#667085]">
          Untuk tetap terhubung dengan kami, silakan masuk dengan informasi pribadi Anda melalui alamat email dan kata sandi.
          </p>
        </div>
        <form onSubmit={onSubmit} className="w-full px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="loginEmail"
            >
              Email
            </label>
            <input
              onClick={resetInput}
              id="loginEmail"
              name="email"
              type="text"
              value={email}
              placeholder="Email"
              onChange={onChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="loginPassword"
            >
              Password
            </label>
            <input
              onClick={resetInput}
              id="loginPassword"
              name="password"
              type="password"
              value={password}
              placeholder="Enter your password"
              onChange={onChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            />
            {/* <p className="text-red-500 text-xs italic">Please choose a password.</p> */}
            {messageLogin && (
              <div
                className="mt-1 text-sm text-red-500"
                dangerouslySetInnerHTML={{ __html: messageLogin }}
              />
            )}
          </div>
          <div className="flex flex-col items-center justify-between">
            <Button width={"full"} type={"submit"}>
              {isLoading ? (
                <div
                  class="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              ) : (
                <span>
                  Masuk
                </span>
              )}
            </Button>
            <p className="mt-3 text-[#667085]">
              Belum punya akun?
              <Link to={"/register"}>
                <span
                  className="inline-block align-baseline text-[#1480E3] hover:text-blue-800 ml-1"
                  href="#"
                >
                  Daftar
                </span>
              </Link>
            </p>
            <p className="mt-3 text-[#667085] flex">
              Lupa password?
              <div>
                <span
                  onClick={handleShowModal}
                  className="inline-block align-baseline cursor-pointer text-[#1480E3] hover:text-blue-800 ml-1"
                  href="#"
                >
                  Klik
                </span>
              </div>
            </p>
          </div>
        </form>
      </div>

      <ModalForgotPassword
        show={showModal}
      />

      <ToastContainer />
    </>
  );
}

export default Login;

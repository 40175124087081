import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import imageKYCAlert from "../../assets/images/auth/kyc-alert.png";
import Button from "../../components/utilities/button";

function AuthSuccess() {
  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);
  console.log(user);

  const [messageAuth, setMessageAuth] = useState(message);
  const { email } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setMessageAuth(message);

    // dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  return (
    <>
      <div className="flex flex-col justify-center md:items-center md:w-2/4 xl:w-2/6 mx-auto h-screen">
        <div className="flex flex-col items-center px-8 mb-3">
          <img src={imageKYCAlert} alt="KYC" />
          <h1 className="mb-4 font-bold">Halo {email}</h1>
          <p className="text-sm text-[#727067] text-center mb-4">Untuk menggunakan aplikasi ini, Anda harus melengkapi kepatuhan KYC. Lengkapi data Anda</p>
          <Link to={"/kyc"}>
            <Button width={"full"}>Lengkapi KYC</Button>
          </Link>
          <span className="text-sm text-[#727067] mt-4">Tidak untuk saat ini <Link to={"/"} className="text-sm text-[#1480E3]">Keluar</Link></span>
        </div>
      </div>
    </>
  );
}

export default AuthSuccess;

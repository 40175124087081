import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import withdrawIDRService from "./withdrawIDRService";
import Cookies from "js-cookie";

// get user
const encodeString = Cookies.get("user");
const decodedString = encodeString ? decodeURIComponent(encodeString) : null;
const user = encodeString ? JSON.parse(decodedString) : null;

const initialState = {
  user: user ? user : null,
  withdrawIDR: [],
  bankName: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// create withdraw IDR
export const createWithdrawIDR = createAsyncThunk("withdraws/create", async (withdrawData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.data.user.user_jwt_token;
    // console.log(token);
    return await withdrawIDRService.createWithdrawIDR(withdrawData, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    if (error.response.status === 401) {
      Cookies.set("user", JSON.stringify(error.response.data), { expires: 2 });
    }

    return thunkAPI.rejectWithValue(message);
  }
});

export const withdrawIDRSlice = createSlice({
  name: "withdrawIDR",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createWithdrawIDR.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createWithdrawIDR.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = state.isSuccess === false ? true : false;
        state.isSuccess = action.payload.success;
        state.message = action.payload.msg;
        state.user = action.payload;
        state.withdrawIDR = action.payload;
      })
      .addCase(createWithdrawIDR.rejected, (state, action) => {
        const encodeString = Cookies.get("user");
        const decodedString = encodeString ? decodeURIComponent(encodeString) : null;
        const user = encodeString ? JSON.parse(decodedString) : null;

        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.msg;
        state.user = user;
        state.withdrawIDR = action.payload;
      });
  },
});

export const { reset } = withdrawIDRSlice.actions;
export default withdrawIDRSlice.reducer;

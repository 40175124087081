import axios from "axios";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL = process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

// create withdraw XAU
const createWithdrawXAU = async (withdrawData, token) => {
  console.log(withdrawData, token);
  const config = {
    headers: {
      JWT: token,
    },
  };

  const response = await axios.post(API_URL + "withdraw_crypto/create", withdrawData, config);

  return response.data;
};

// const withdrawXAUService = {
//   createWithdrawXAU,
// };

export default createWithdrawXAU;

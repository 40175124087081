import React from "react";
import { HiOutlineClock } from "react-icons/hi2";
import iconSmartphone from "../../assets/icons/smartphone.svg";
import iconTime from "../../assets/icons/time.svg";
import iconUsers from "../../assets/icons/users.svg";
import iconZap from "../../assets/icons/zap.svg";

function Feature() {
  return (
    <>
      <div className="px-4 md:px-20 mb-14">
        <div className="md:flex mb-12 justify-center">
          <div className="flex flex-col items-center px-8">
            <h2 className="text-2xl font-semibold">Fitur Unggulan</h2>
            <p className="text-gray-500 text-center">Token XAU akan berfungsi sebagai aset digital yang benar-benar demokratis yang didukung oleh Emas.</p>
          </div>
        </div>
        <div className="md:flex md:flex-wrap">
          <div className="md:basis-2/4 px-8 md:px-0 md:pr-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconTime} />
            </div>
            <h3 className="text-lg font-semibold mb-2">Dapat Dikonversi Menjadi Emas Fisik</h3>
            <p className="text-gray-500">XAU mewakili kepemilikan emas fisik yang disimpan di fasilitas penyimpanan dengan keamanan tinggi, yaitu brankas pihak ketiga yang diamankan dan diaudit. Pemegang token XAU memiliki hak atas sejumlah emas yang ada, yang dapat diklaim kapan saja. Dibangun di jaringan blockchain Ethereum, Token XAU dapat diperdagangkan di bursa terpusat dan Platform Xaurius.</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pl-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconZap} />
            </div>
            <h3 className="text-lg font-semibold mb-2">Volatilitas Rendah</h3>
            <p className="text-gray-500">Tidak seperti mata uang kripto tradisional yang tidak memiliki nilai inheren dan hanya mengandalkan sensasi penawaran dan permintaan untuk mendapatkan nilainya, Token XAU memiliki nilai dunia nyata (real-world) yang berasal dari cadangan emas yang mendasarinya, yang memastikan stabilitas dan volatilitas minimum.</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pr-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconSmartphone} />
            </div>
            <h3 className="text-lg font-semibold mb-2">Potensi untuk Berkembang</h3>
            <p className="text-gray-500">Karena Token XAU didukung oleh emas fisik yang disimpan di brankas di seluruh dunia, ia berpotensi untuk mencatat pertumbuhan nilai melalui apresiasi nilai emas di pasar global. Peningkatan nilai emas ini dicatat langsung oleh Token XAU dan akan terlihat dalam harga pertukaran.</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pl-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconUsers} />
            </div>
            <h3 className="text-lg font-semibold mb-2">Likuiditas Maksimal</h3>
            <p className="text-gray-500">Dengan XAURIUS, sekarang pedagang dapat menukar nilai terhadap mata uang apa pun yang ia inginkan, langsung melalui aplikasi seluler atau webnya dalam hitungan detik.</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pr-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconSmartphone} />
            </div>
            <h3 className="text-lg font-semibold mb-2">Keamanan</h3>
            <p className="text-gray-500">Token XAU berfungsi sebagai token yang diamankan secara kriptografis, yang digunakan di jaringan blockchain Ethereum. Semua data diamankan pada jaringan blockchain yang terdesentralisasi dengan memastikan tidak ada kemungkinan untuk kegagalan kecil atau terpusat. Selain itu, emas fisik diamankan dalam brankas pihak ketiga yang berlisensi selagi diasuransikan oleh perusahaan terkemuka.</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pl-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconUsers} />
            </div>
            <h3 className="text-lg font-semibold mb-2">Terskalakan (Scalable) dan Mudah Disesuaikan (Adaptable)</h3>
            <p className="text-gray-500">Di XAURIUS, tujuan kami adalah untuk memfasilitasi industri rantai pasokan Emas daripada merusaknya. Semua solusi Platform XAURIUS dapat dengan mudah diintegrasikan dengan sistem manajemen rantai pasokan yang ada dan dirancang dengan faktor-faktor penting seperti skalabilitas, fleksibilitas, dan ketahanan untuk menangani alur kerja rantai pasokan, bahkan yang paling kompleks sekalipun.</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pr-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconSmartphone} />
            </div>
            <h3 className="text-lg font-semibold mb-2">Transparansi</h3>
            <p className="text-gray-500">Sifat blockchain yang transparan dan tidak dapat diubah menyediakan akses terbuka pada informasi setiap saat. Dengan demikian, ini memastikan proses yang sepenuhnya transparan, catatan data yang akurat, dan ketentuan yang sama bagi setiap orang.</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pl-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconUsers} />
            </div>
            <h3 className="text-lg font-semibold mb-2">Biaya Transaksi Murah</h3>
            <p className="text-gray-500">Tidak seperti platform perbankan dan pengiriman uang tradisional lain yang mengenakan biaya hingga 10% dari biaya transaksi, transaksi yang terjadi di ekosistem XAURIUS akan dikenakan biaya yang jauh lebih rendah.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Feature;

import React, { useEffect } from "react";
import Header from "../../components/client/Header";
import Footer from "../../components/client/Footer";
import ContentFAQ from "../../components/pages/contentFAQ";

function FAQ() {
  useEffect(() => {
    document.title = "Xaurius | FAQ"
  }, []);

  return (
    <>
      <Header />
      <ContentFAQ />
      <Footer />
    </>
  );
}

export default FAQ;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { logout, reset } from "../features/auth/authSlice";
import { ToastContainer, toast } from "react-toastify";
import imageLogoXaurius from "../assets/images/logo-xaurius-light.png";
import {
  HiChevronDown,
  HiChevronUp,
  HiOutlineArrowDownCircle,
  HiOutlineArrowUpCircle,
  HiOutlineArrowTrendingDown,
  HiOutlineArrowTrendingUp,
  HiOutlineArrowsUpDown,
  HiOutlineHome,
  HiOutlineCircleStack,
  HiOutlinePlusCircle,
  HiOutlineClock,
  HiOutlineUser,
  HiPlus,
  HiXMark,
} from "react-icons/hi2";
import { useDispatch } from "react-redux";

// menu approaches
// - menu dibagi 2 views, untuk mobile dan desktop/iPad/tablet.
// - jika desktop/iPad/tablet, menu sidebar. Jika mobile, menu bottom bar.
// - code dibagi 3, atas untuk menu desktop/iPad/tablet, tengah untuk mobile menu, dan terakhir popup slide untuk transactions.

function Nav() {
  const [showTransactionMenuMobile, setShowTransactionMenuMobile] =
    useState(false); // toggle transaction menu
  const [
    showTransactionMenuMobileDesktop,
    setShowTransactionMenuMobileDesktop,
  ] = useState(false); // toggle transaction menu

  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    toast.success("Berhasil keluar");
  };

  return (
    <>
      {/* desktop & tablet menu */}
      <nav className="hidden md:block fixed top-0 left-0 md:w-[30vw] xl:w-[20vw] h-full  z-10">
        <ul className="wrapper px-4">
          <li className="flex my-6">
            <div className="logo">
              <NavLink to={"/"}>
                <img
                  src={imageLogoXaurius}
                  className="w-[116px]"
                  alt="Xaurius Logo"
                />
              </NavLink>
            </div>
          </li>

          <div id="dashboard">
            <li className="flex items-center my-6 hover:text-[#938800]">
              <NavLink to={"/dashboard"}>
                <HiOutlineHome size={24} />
              </NavLink>
              <NavLink to={"/dashboard"}>
                <span className="inline-block ml-4 text-base">Dashboard</span>
              </NavLink>
            </li>
            <li className="flex items-center my-6 hover:text-[#938800]">
              <NavLink to={"/deposit/idr"}>
                <HiOutlineCircleStack size={24} />
              </NavLink>
              <NavLink to={"/deposit/idr"}>
                <span className="inline-block ml-4 text-base">Pertukaran</span>
              </NavLink>
            </li>
            <div
              onClick={() =>
                setShowTransactionMenuMobileDesktop(
                  !showTransactionMenuMobileDesktop
                )
              }
              className="flex flex-col my-6 relative  cursor-pointer"
            >
              <div className="flex items-center hover:text-[#938800]">
                <HiPlus size={24} />
                <span className="inline-block ml-4 text-base">
                  Transaksi
                </span>
                <button
                  className="basis-1/4 ml-2"
                  onClick={() =>
                    setShowTransactionMenuMobileDesktop(
                      !showTransactionMenuMobileDesktop
                    )
                  }
                >
                  {showTransactionMenuMobileDesktop === true ? (
                    <HiChevronUp />
                  ) : (
                    <HiChevronDown />
                  )}
                </button>
              </div>
              {showTransactionMenuMobileDesktop ? (
                // <div className="relative top-0 left-0">
                <ul className="py-2 ml-4">
                  <li className="flex items-center my-4 hover:text-[#938800]">
                    <NavLink to={"/deposit/idr"}>
                      <HiOutlineArrowDownCircle size={24} />
                    </NavLink>
                    <NavLink to={"/deposit/idr"}>
                      <span className="inline-block ml-2 text-base">
                        Setoran IDR
                      </span>
                    </NavLink>
                  </li>
                  <li className="flex items-center my-4 hover:text-[#938800]">
                    <NavLink to={"/buy-xau"}>
                      <HiOutlineArrowTrendingDown size={24} />
                    </NavLink>
                    <NavLink to={"/buy-xau"}>
                      <span className="inline-block ml-2 text-base">Beli XAU Token</span>
                    </NavLink>
                  </li>
                  <li className="flex items-center my-4 hover:text-[#938800]">
                    <NavLink to={"/sell-xau"}>
                      <HiOutlineArrowTrendingUp size={24} />
                    </NavLink>
                    <NavLink to={"/sell-xau"}>
                      <span className="inline-block ml-2 text-base">Jual XAU Token</span>
                    </NavLink>
                  </li>
                  <li className="flex items-center my-4 hover:text-[#938800]">
                    <NavLink to={"/transfer-xau"}>
                      <HiOutlineArrowsUpDown size={24} />
                    </NavLink>
                    <NavLink to={"/transfer-xau"}>
                      <span className="inline-block ml-2 text-base">
                        Crypto
                      </span>
                    </NavLink>
                  </li>
                  <li className="flex items-center mt-4 hover:text-[#938800]">
                    <NavLink to={"/withdraw/idr"}>
                      <HiOutlineArrowUpCircle size={24} />
                    </NavLink>
                    <NavLink to={"/withdraw/idr"}>
                      <span className="inline-block ml-2 text-base">
                        Penarikan IDR
                      </span>
                    </NavLink>
                  </li>
                </ul>
              ) : // </div>
              null}
            </div>
            <li className="flex items-center my-6 hover:text-[#938800]">
              <NavLink to={"/history"}>
                <HiOutlineClock size={24} />
              </NavLink>
              <NavLink to={"/history"}>
                <span className="inline-block ml-4 text-base">Riwayat</span>
              </NavLink>
            </li>
            <li className="flex items-center my-6 hover:text-[#938800]">
              <NavLink to={"/profile"}>
                <HiOutlineUser size={24} />
              </NavLink>
              <NavLink to={"/profile"}>
                <span className="inline-block ml-4 text-base">Profile</span>
              </NavLink>
            </li>
          </div>
          {/* user profile */}
          {/* <li className="flex items-center my-6">
            <NavLink to={"/dashboard"}>
              <span>Foto Profil</span>
            </NavLink>
            <NavLink to={"/dashboard"}>
              <span>Olivia Rhye</span>
            </NavLink>
            <NavLink to={"/dashboard"}>
              <span>username@email.com</span>
            </NavLink>
            <NavLink to={"/dashboard"}>
              <span>Logout</span>
            </NavLink>
          </li> */}
        </ul>

        <div className="logout mt-64 flex items-center justify-center pt-28">
          <div className="flex flex-col gap-4">
            <div className="w-[260px] h-[1px] bg-[#626435A3]"></div>
            <div
              onClick={onLogout}
              className="flex gap-2 items-center cursor-pointer px-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 16L7 12M7 12L11 8M7 12L21 12M16 16V17C16 18.6569 14.6569 20 13 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H13C14.6569 4 16 5.34315 16 7V8"
                  stroke="#626435"
                  strokeWidth="2"
                  trokelinecapp="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-[#626435] font-bold">Keluar</p>
            </div>
          </div>
        </div>
      </nav>

      {/* mobile menu */}
      {/* <nav className="md:hidden fixed bottom-0 left-0 z-[2] w-full bg-gray-600 text-white"> */}
      <nav className="md:hidden fixed bottom-0 left-0 z-[2] w-full bg-white text-gray-600 border-t-gray-100 border-t">
        <ul className="flex py-4">
          <li className="flex flex-col items-center grow shrink-0 basis-[64px]">
            <NavLink to={"/dashboard"}>
              <HiOutlineHome size={24} />
            </NavLink>
            <NavLink to={"/dashboard"}>
              <span className="inline-block mt-2 text-xs">Dashboard</span>
            </NavLink>
          </li>
          <li className="flex flex-col items-center grow shrink-0 basis-[64px]">
            <NavLink to={"/deposit/idr"}>
              <HiOutlineCircleStack size={24} />
            </NavLink>
            <NavLink to={"/deposit/idr"}>
              <span className="inline-block mt-2 text-xs">Pertukaran</span>
            </NavLink>
          </li>
          <li
            className="flex flex-col items-center grow shrink-0 basis-[64px]"
            onClick={() =>
              setShowTransactionMenuMobile(!showTransactionMenuMobile)
            }
          >
            <HiOutlinePlusCircle size={24} />
            <span className="inline-block mt-2 text-xs">Transaksi</span>
          </li>
          <li className="flex flex-col items-center grow shrink-0 basis-[64px]">
            <NavLink to={"/history"}>
              <HiOutlineClock size={24} />
            </NavLink>
            <NavLink to={"/history"}>
              <span className="inline-block mt-2 text-xs">Riwayat</span>
            </NavLink>
          </li>
          <li className="flex flex-col items-center grow shrink-0 basis-[64px]">
            <NavLink to={"/profile"}>
              <HiOutlineUser size={24} />
            </NavLink>
            <NavLink to={"/profile"}>
              <span className="inline-block mt-2 text-xs">Profile</span>
            </NavLink>
          </li>
        </ul>
      </nav>

      {/* popup transactions menu */}
      {showTransactionMenuMobile ? (
        <>
          <div className="fixed bottom-0 z-[2] w-full">
            <div
              className={`bg-white border border-t-slate-400 rounded-lg md:hidden ${
                showTransactionMenuMobile ? "block" : "hidden"
              }`}
            >
              <ul className="flex flex-col p-4">
                <li
                  className="flex justify-end"
                  onClick={() =>
                    setShowTransactionMenuMobile(!showTransactionMenuMobile)
                  }
                >
                  <span className="w-[32px] h-[32px] border border-radius rounded-[50%] bg-white flex items-center justify-center">
                    <HiXMark size={24} />
                  </span>
                </li>
                <li className="flex items-center my-4">
                  <NavLink to={"/deposit/idr"}>
                    <HiOutlineArrowDownCircle size={24} />
                  </NavLink>
                  <NavLink to={"/deposit/idr"}>
                    <span className="inline-block ml-2 text-base">Deposit</span>
                  </NavLink>
                </li>
                <li className="flex items-center my-4">
                  <NavLink to={"/buy-xau"}>
                    <HiOutlineArrowTrendingDown size={24} />
                  </NavLink>
                  <NavLink to={"/buy-xau"}>
                    <span className="inline-block ml-2 text-base">Buy</span>
                  </NavLink>
                </li>
                <li className="flex items-center my-4">
                  <NavLink to={"/sell-xau"}>
                    <HiOutlineArrowTrendingUp size={24} />
                  </NavLink>
                  <NavLink to={"/sell-xau"}>
                    <span className="inline-block ml-2 text-base">Sell</span>
                  </NavLink>
                </li>
                <li className="flex items-center my-4">
                  <NavLink to={"/transfer-xau"}>
                    <HiOutlineArrowsUpDown size={24} />
                  </NavLink>
                  <NavLink to={"/transfer-xau"}>
                    <span className="inline-block ml-2 text-base">
                      Transfer
                    </span>
                  </NavLink>
                </li>
                <li className="flex items-center my-4">
                  <NavLink to={"/withdraw/idr"}>
                    <HiOutlineArrowUpCircle size={24} />
                  </NavLink>
                  <NavLink to={"/withdraw/idr"}>
                    <span className="inline-block ml-2 text-base">
                      Withdraw
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </>
      ) : null}

      <ToastContainer />
    </>
  );
}

export default Nav;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bankXAUService from "./bankXAUService";
import Cookies from "js-cookie";

const initialState = {
  message: "",
  bankXaurius: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
};

// get bank xaurius
export const getBankXaurius = createAsyncThunk("bank/xau", async (_, thunkAPI) => {
  // console.log(thunkAPI);
  try {
    const token = thunkAPI.getState().auth.user.data.user.user_jwt_token; // get token from auth state
    // console.log(token);
    return await bankXAUService.getBankXaurius(token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.msg) || error.message || error.toString();

    if (error.response.status === 401) {
      Cookies.set("user", JSON.stringify(error.response.data), { expires: 2 });
    }

    return thunkAPI.rejectWithValue(message); // return axios error to message state
  }
});

export const bankXAUSlice = createSlice({
  name: "bankXAU",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankXaurius.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBankXaurius.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.isLoading = false;
        state.isError = state.isSuccess === false ? true : false;
        state.isSuccess = action.payload.success;
        state.message = action.payload.msg;
        state.user = action.payload;
        state.bankXaurius = action.payload.data;
      })
      .addCase(getBankXaurius.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.bankXaurius = null;
      });
  },
});

export const { reset } = bankXAUSlice.actions;
export default bankXAUSlice.reducer;

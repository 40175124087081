import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import uploadService from './uploadService';

export const uploadFile = createAsyncThunk('upload/uploadFile', async (data, { getState }) => {
  const { auth } = getState();
  const token = auth.token;
  try {
    return await uploadService.createUpload(data, token);

  } catch (error) {
    throw error;
  }
});

const uploadSlice = createSlice({
  name: 'upload',
  initialState: {
    uploading: false,
    success: false,
    error: null,
    responseMessage: '',
    uploadedData: null, // Menambahkan state untuk menyimpan data yang diupload
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadFile.pending, (state) => {
      state.uploading = true;
      state.success = false;
      state.error = null;
      state.responseMessage = '';
    });
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.uploading = false;
      state.success = action.payload.success;
      console.log("slice", action.payload);
      state.error = null;
      state.responseMessage = 'File berhasil diupload!';
      state.uploadedData = action.payload; // Menyimpan data dari respons di state uploadedData
    });
    builder.addCase(uploadFile.rejected, (state, action) => {
      state.uploading = false;
      state.success = false;
      console.log("slice", action.payload);
      state.error = action.error.message;
      state.responseMessage = 'Gagal mengupload file.';
    });
  },
});

export default uploadSlice.reducer;
// export { uploadFile };

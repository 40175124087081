import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { getBalance } from "../../features/balance/idr/balanceIDRSlice";
import { createSell, reset } from "../../features/sell/xau/sellXAUSlice";
import { fetchPriceData } from "../../features/price/lastPriceSlice";
import { getHistory } from "../../features/history/historySlice";
import SuccessAnimation from "../animations/SuccessAnimation";
import WarningImg from "../../assets/images/warning-icon.png";
import formatToRupiah from "../helper/formatToRupiah";
import balanceIDRService from "../../features/balance/idr/balanceIDRService";
import Cookies from "js-cookie";

// modal custom
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

function FormSellXAU() {
  // const { balanceIDR } = useSelector((state) => state.balanceIDR);
  const { user, isError, isSuccess, message } = useSelector(
    (state) => state.sellXAU
  );
  const [balanceData, setBalanceData] = useState(null);
  const [resMsg, setResMsg] = useState("");
  const [resIsSuccess, setResIsuccess] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [formData, setFormData] = useState({
    amount_xau: "",
  });

  // console.log(balanceIDR);

  const { amount_xau } = formData;

  const [messageAmountXAU, setMessageAmountXAU] = useState(message);
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const priceData = useSelector((state) => state.lastPrice.priceData);
  // const [messageTotalAmount, setMessageTotalAmount] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = Cookies.get("jwt");

  useEffect(() => {
    if (isError) {
      setResIsuccess(false);
      setResMsg(message);
    }

    if (isSuccess) {
      setResIsuccess(true);
      setResMsg(message);
      dispatch(getHistory());
    }

    dispatch(reset());
    dispatch(getBalance());
    dispatch(fetchPriceData());
    // dispatch(reset());

    const fetchBalanceData = async () => {
      try {
        const data = await balanceIDRService.getBalance(token);
        const filteredData = data.data.filter(
          (item) => item.currency === "XAU"
        );
        setBalanceData(filteredData);
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    };

    fetchBalanceData();
  }, [
    user,
    isError,
    isSuccess,
    message,
    navigate,
    dispatch,
    amount_xau,
    token,
  ]);

  const onChange = (e) => {
    const input = e.target.value;
    // Hanya izinkan input berupa angka dan koma (desimal)
    const onlyNums = input.replace(/[^0-9.,]/g, "");
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: onlyNums,
    }));

    calculateEstimate(input);

    // cek jika user typing . di jumlah deposit
    if (amount_xau === "") {
      setMessageAmountXAU("");
    } else if (isNaN(amount_xau)) {
      setMessageAmountXAU(`Hanya format number!`);
    } else {
      setMessageAmountXAU("");
    }
  };

  const calculateEstimate = (val) => {
    if (val) {
      const pricePerAux = parseFloat(priceData.sell);
      const amount = parseFloat(val);
      const estimated = pricePerAux * amount;
      setEstimatedPrice(estimated);
    } else {
      setEstimatedPrice(0);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (amount_xau === "" || amount_xau === 0) {
      setMessageAmountXAU("Nilai tidak boleh kosong");
    } else if (isError) {
      setMessageAmountXAU(message);
    } else if (!isError) {
      dispatch(createSell(formData));
      setMessageAmountXAU("");
      openModal();
    }
  };

  // handle modal
  let subtitle;

  function openModal() {
    setIsOpen(true);
    setTimeout(() => {
      closeModal();
    }, 3000);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    setFormData({ ...formData, amount_xau: "" });
    setEstimatedPrice(0);
  }

  return (
    <>
      <div className="rounded-xl shadow-lg p-4 mb-12 h-full">
        <h1 className="pb-4 mb-8 border-b border-b-gray-200">Jual XAU Token</h1>
        <div className="flex justify-between mb-8">
          <span className="text-gray-500">Token Tersedia</span>
          <span className="text-gray-500">
            {balanceData
              ? parseFloat(balanceData[0].balance_value).toFixed(2)
              : null}
            &nbsp;
            {balanceData ? balanceData[0].currency : null}
          </span>
        </div>
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="amount_xau"
            >
              Saya Ingin Menjual
            </label>
            <div className="relative">
              <span className="absolute right-2 top-2 px-2 py-0 bg-[#FEF0C7] text-[#DC6803] rounded-md text-sm">
                XAU
              </span>
              <input
                id="sellXAUAmount"
                name="amount_xau"
                type="text"
                value={amount_xau}
                placeholder="0"
                onChange={onChange}
                className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            {messageAmountXAU && (
              <div className="mt-1 text-sm text-red-500">
                {messageAmountXAU}
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Taksiran
            </label>
            <span className="text-[#667085] text-sm">
              {formatToRupiah(estimatedPrice)}
            </span>
          </div>
          <div className="flex flex-col items-center justify-between">
            <button
              className="w-full bg-btn-gold shadow-btn-shadow hover:bg-blue-70 text-[#344054] font-bold py-2 px-4 mb-3 rounded-[34px] focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Jual
            </button>
          </div>
        </form>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="w-full lg:w-[300px] flex flex-col items-center">
          {!resIsSuccess ? (
            <img src={WarningImg} alt="warning" width="150" />
          ) : (
            <SuccessAnimation />
          )}
          <span
            className="mt-4 mb-1"
            ref={(_subtitle) => (subtitle = _subtitle)}
          >
            <h2 className="text-[#344054] font-bold max-w-md text-center">
              {resMsg}
            </h2>
          </span>
        </div>
      </Modal>
    </>
  );
}

export default FormSellXAU;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "./Loading";
import { getBalance } from "../features/balance/idr/balanceIDRSlice";
import { getProfile } from "../features/profile/profileSlice";
import formatToRupiah from "./helper/formatToRupiah";
import lastPriceData from "../features/price/lastPriceService";

function TotalBalance() {
  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);
  const { balanceIDR, isLoading } = useSelector(
    (state) => state.balanceIDR
  );

  const [totalbalance, setTotalBalance] = useState();
  const [lastPrice, setLastPrice] = useState();

  const dataBalance =
    balanceIDR && balanceIDR.find((o) => o.currency === "IDR");
  const dataBalanceXAU =
    balanceIDR && balanceIDR.find((o) => o.currency === "XAU");
  const saldoIDR = Math.round(dataBalance?.balance_value);
  const jumlahXAU = Math.round(dataBalanceXAU?.balance_value);
  const lastPriceRound = Math.round(lastPrice);
  const assetXAU = Number(dataBalanceXAU?.balance_value);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile()); // check kyc status
    dispatch(getBalance());

    const getLastPrice = async () => {
      try {
        const res = await lastPriceData();
        setLastPrice(res.data.price);
      } catch (err) {
        console.log(err.response.data.success);
      }
    };

    getLastPrice();

    const totalBalance = lastPriceRound * jumlahXAU + saldoIDR;
    setTotalBalance(totalBalance);
  }, [user, dispatch, lastPriceRound, jumlahXAU, saldoIDR]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {user && user.success ? (
        <>
          {profile.data && profile.data.status_kyc === "approved" ? (
            <>
              <div className="rounded-xl shadow-lg bg-gradient-to-r from-gold1 to-gold2 h-[210px] mb-8">
                <div className="flex flex-col h-full px-8 py-6">
                  <h3 className="text-md">Total Balance</h3>
                  <div className="flex mb-8">
                    <div className="flex">
                      <h1 className="font-semibold text-2xl">
                        {dataBalance ? formatToRupiah(totalbalance) : 0}
                      </h1>
                    </div>
                  </div>
                  <div className="flex grow items-end">
                    <div className="grow pr-6">
                      <h5 className="text-xs mb-2">Saldo Tersedia</h5>
                      <div className="flex">
                        <h1 className="font-medium text-xs">
                          {formatToRupiah(saldoIDR)}
                        </h1>
                      </div>
                    </div>
                    <div className="grow pl-6">
                      <h5 className="text-xs mb-2">Asset</h5>
                      <div className="flex">
                        <h1 className="font-medium text-xs">
                          {assetXAU.toFixed(2)} XAU
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="rounded-xl shadow-lg bg-gradient-to-r from-gold1 to-gold2 h-[210px] mb-8">
                <div className="flex flex-col h-full px-8 py-6">
                  <h3 className="text-md">Total Balance</h3>
                  <div className="flex mb-8">
                    <div className="flex">
                      <h1 className="font-semibold text-xl flex items-end pr-1">
                        Rp
                      </h1>
                      <h1 className="font-semibold text-2xl">0</h1>
                    </div>
                  </div>
                  <div className="flex grow items-end">
                    <div className="grow pr-6">
                      <h5 className="text-xs mb-2">Saldo Tersedia</h5>
                      <div className="flex">
                        <h1 className="font-medium text-sm flex items-end pr-1">
                          0
                        </h1>
                        <h1 className="font-medium text-sm">0</h1>
                      </div>
                    </div>
                    <div className="grow pl-6">
                      <h5 className="text-xs mb-2">Nilai Asset</h5>
                      <div className="flex">
                        <h1 className="font-medium text-sm flex items-end pr-1">
                          0
                        </h1>
                        <h1 className="font-medium text-sm">0</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : null}
    </>
  );
}

export default TotalBalance;

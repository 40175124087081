import React, { useState } from "react";
import formatToRupiah from "../helper/formatToRupiah";
import copy from "clipboard-copy";
import Copy from "../../assets/icons/copy.png";
import Copied from "../../assets/icons/copied.png";
// import { Link } from "react-router-dom";

function HistoryTransaction({
  date,
  judul,
  status,
  total,
  id,
  ammountIDR,
  ammountXAU,
  jumlahIDR,
  jumlahXAU,
  txhash,
}) {
  const [isHovered, setHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setIsClicked(false);
  };

  const handleCopyClick = (val) => {
    setIsClicked(true);
    copy(val);
  };
  return (
    <div className="border rounded-md mb-6">
      <div>
        <div className="flex items-center gap-1 pl-2">
          {judul === "Penjualan XAU" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
            >
              <path
                d="M17.928 0.568059C17.8367 0.34418 17.662 0.166272 17.4422 0.0732978C17.334 0.0263496 17.2178 0.00144762 17.1003 0H12.6016C12.3629 0 12.1341 0.0965306 11.9654 0.268356C11.7966 0.440181 11.7018 0.673226 11.7018 0.916224C11.7018 1.15922 11.7966 1.39227 11.9654 1.56409C12.1341 1.73592 12.3629 1.83245 12.6016 1.83245H14.9319L9.90235 6.95414L6.94221 3.9306C6.85856 3.84472 6.75905 3.77656 6.64941 3.73005C6.53977 3.68353 6.42217 3.65958 6.30339 3.65958C6.18461 3.65958 6.06701 3.68353 5.95737 3.73005C5.84773 3.77656 5.74822 3.84472 5.66458 3.9306L0.266141 9.42795C0.18181 9.51312 0.114875 9.61445 0.0691962 9.7261C0.0235176 9.83776 0 9.95751 0 10.0785C0 10.1994 0.0235176 10.3192 0.0691962 10.4308C0.114875 10.5425 0.18181 10.6438 0.266141 10.729C0.349784 10.8149 0.449296 10.883 0.558938 10.9295C0.668579 10.9761 0.78618 11 0.904956 11C1.02373 11 1.14133 10.9761 1.25097 10.9295C1.36062 10.883 1.46013 10.8149 1.54377 10.729L6.30339 5.873L9.26353 8.89653C9.34718 8.98241 9.44669 9.05057 9.55633 9.09709C9.66597 9.1436 9.78357 9.16755 9.90235 9.16755C10.0211 9.16755 10.1387 9.1436 10.2484 9.09709C10.358 9.05057 10.4575 8.98241 10.5412 8.89653L16.2005 3.12432V5.49734C16.2005 5.74034 16.2953 5.97339 16.464 6.14521C16.6328 6.31704 16.8616 6.41357 17.1003 6.41357C17.3389 6.41357 17.5677 6.31704 17.7365 6.14521C17.9052 5.97339 18 5.74034 18 5.49734V0.916224C17.9986 0.796494 17.9741 0.678209 17.928 0.568059Z"
                fill="#717171"
              />
            </svg>
          ) : judul === "Pembelian XAU" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
            >
              <path
                d="M17.928 10.4319C17.8367 10.6558 17.662 10.8337 17.4422 10.9267C17.334 10.9737 17.2178 10.9986 17.1003 11H12.6016C12.3629 11 12.1341 10.9035 11.9654 10.7316C11.7966 10.5598 11.7018 10.3268 11.7018 10.0838C11.7018 9.84078 11.7966 9.60773 11.9654 9.43591C12.1341 9.26408 12.3629 9.16755 12.6016 9.16755H14.9319L9.90235 4.04586L6.94221 7.0694C6.85856 7.15528 6.75905 7.22344 6.64941 7.26995C6.53977 7.31647 6.42217 7.34042 6.30339 7.34042C6.18461 7.34042 6.06701 7.31647 5.95737 7.26995C5.84773 7.22344 5.74822 7.15528 5.66458 7.0694L0.266141 1.57205C0.18181 1.48688 0.114875 1.38555 0.0691962 1.2739C0.0235176 1.16224 0 1.04249 0 0.921537C0 0.800584 0.0235176 0.680829 0.0691962 0.569178C0.114875 0.457528 0.18181 0.356193 0.266141 0.271018C0.349784 0.185142 0.449296 0.116979 0.558938 0.0704634C0.668579 0.0239479 0.78618 1.03402e-07 0.904956 1.03402e-07C1.02373 1.03402e-07 1.14133 0.0239479 1.25097 0.0704634C1.36062 0.116979 1.46013 0.185142 1.54377 0.271018L6.30339 5.127L9.26353 2.10347C9.34718 2.01759 9.44669 1.94943 9.55633 1.90291C9.66597 1.8564 9.78357 1.83245 9.90235 1.83245C10.0211 1.83245 10.1387 1.8564 10.2484 1.90291C10.358 1.94943 10.4575 2.01759 10.5412 2.10347L16.2005 7.87568V5.50266C16.2005 5.25966 16.2953 5.02661 16.464 4.85479C16.6328 4.68296 16.8616 4.58643 17.1003 4.58643C17.3389 4.58643 17.5677 4.68296 17.7365 4.85479C17.9052 5.02661 18 5.25966 18 5.50266V10.0838C17.9986 10.2035 17.9741 10.3218 17.928 10.4319Z"
                fill="#717171"
              />
            </svg>
          ) : judul === "Penarikan XAU" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M1 3.99985L3.29289 1.70696C3.68342 1.31643 4.31658 1.31643 4.70711 1.70696L7 3.99985M4 1.99985L4 13.9998M10 12.9998L12.2929 15.2927C12.6834 15.6833 13.3166 15.6833 13.7071 15.2927L16 12.9998M13 14.9998V2.99985"
                stroke="#717171"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M8 10L10.2929 7.70711C10.6834 7.31658 11.3166 7.31658 11.7071 7.70711L14 10M11 8V15M1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11Z"
                stroke="#717171"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          )}
          <h1 className="py-8 px-4">{judul}</h1>
        </div>
        <div className="grid grid-cols-3 py-4 px-2 border-t bg-[#EBEBE9]">
          <div className="flex flex-col flex-auto  text-xs">
            <span className="mb-2 text-[#444242] text-xs px-2">
              Tanggal Tarnsaksi
            </span>
            <span className="px-2 font-normal text-black">{date}</span>
          </div>
          <div className="flex flex-col flex-auto items-center text-xs">
            <span className="mb-2 text-[#444242] text-xs px-2">
              {ammountIDR}
            </span>
            {judul === "Penarikan XAU" ||
            judul === "Setoran IDR" ||
            judul === "Penarikan IDR" ? (
              <span className="px-2 font-normal text-black">
                {judul === "Penarikan IDR" ? formatToRupiah(total) : total} {judul === "Penarikan XAU" && "XAU"}
              </span>
            ) : (
              <span className="px-2 font-normal text-black">
                {formatToRupiah(jumlahIDR)}
              </span>
            )}
          </div>

          {judul === "Setoran IDR" || judul === "Penarikan IDR" ? (
            <div className="flex flex-col flex-auto items-end text-xs">
              <div className="flex flex-col items-center">
                <span className="mb-2 text-[#444242] text-xs px-2">
                  Status Transaksi
                </span>
                {status === "file-upload" ? (
                  <span className="px-2 font-normal text-black">
                    Menunggu Konfirmasi
                  </span>
                ) : status === "create" ? (
                  <a
                    href={`/history/upload/${id}`}
                    className="bg-btn-gold shadow-btn-shadow w-40 flex justify-center items-center py-1 rounded-md"
                  >
                    Unggah bukti transfer
                  </a>
                ) : status === "terbayar" ? (
                  <span className="px-2 font-normal text-black">{status}</span>
                ) : (
                  <span className="px-2 font-normal text-black">
                    Transaksi berhasil
                  </span>
                )}
              </div>
            </div>
          ) : judul === "Penarikan XAU" ? (
            <div className="flex flex-col flex-auto items-end text-xs">
              <div className="flex flex-col items-center">
                <span className="mb-2 text-[#444242] text-xs px-2">
                  Address
                </span>
                <span
                  onClick={() => handleCopyClick(txhash)}
                  onMouseOver={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="px-2 font-normal text-black relative"
                >
                  {txhash?.substring(0, 5)}...
                  {txhash?.substring(txhash?.length - 4)}
                  {isHovered && (
                    <img
                      src={isClicked ? Copied : Copy}
                      alt="copy"
                      width="80"
                      className="absolute -top-8"
                    />
                  )}
                </span>
              </div>
            </div>
          ) : (
            <div className="flex flex-col flex-auto items-end text-xs">
              <div className="flex flex-col items-center">
                <span className="mb-2 text-[#444242] text-xs px-2">
                  {ammountXAU}
                </span>
                <span className="px-2 font-normal text-black">{jumlahXAU}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HistoryTransaction;

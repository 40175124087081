import axios from "axios";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL = process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

// get bank user
const getBankUser = async (token) => {
  const config = {
    headers: {
      JWT: token,
    },
  };

  const response = await axios.get(API_URL + "bank_users/detail", config);

  return response.data;
};

// create bank user
const createBankUser = async (bankUserData, token) => {
  console.log(bankUserData, token);
  const config = {
    headers: {
      JWT: token,
    },
  };

  const response = await axios.post(API_URL + "bank_users/create", bankUserData, config);

  return response.data;
};

// update bank user
const updateBankUser = async (bankUserData, token) => {
  console.log(bankUserData, token);
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/x-www-form-urlencoded",
      JWT: token,
    },
  };

  const response = await axios.put(API_URL + "bank_users/update", bankUserData, config);

  return response.data;
};

const bankUserService = {
  getBankUser,
  createBankUser,
  updateBankUser,
};

export default bankUserService;

import React from 'react'
import { useSelector } from 'react-redux';
import Nav from '../../components/Nav';
import NotLogin from '../../components/auth/NotLogin';
import { Link, useParams } from 'react-router-dom';

function DepositDetail() {
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams();

  return (
    <>
      {user && user.success ? (
        <>
          <Nav />
          <div className="md:pl-[30vw] xl:pl-[20vw]">
            <div className="px-6 pb-32">
              <div className='h-screen flex flex-col justify-center items-center'>

            <h2>Deposit Detail</h2>
            <Link className='text-blue-600' to={`/history/upload/${id}`}>Upload bukti pembayaran</Link>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <NotLogin />
        </>
      )}
    </>
  )
}

export default DepositDetail

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineCloudUpload } from "react-icons/ai";
import Nav from "../../components/Nav";
import NotLogin from "../../components/auth/NotLogin";
import { BreadcrumbGoBack } from "../../components/breadcrumb/Breadcrumb";
import { getProfile } from "../../features/profile/profileSlice";
import { updateProfile } from "../../features/profile/updateProfileSlice";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

function ProfileUpdate() {
  const { user } = useSelector((state) => state.auth);
  const { profile, isLoading, isSuccess, message } = useSelector(
    (state) => state.profile
  );
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [avatarPrev, setAvatarPrev] = useState(null);
  // const { isSuccess } = useSelector((state) => state.profileUpdate);
  console.log(profile);

  const [formData, setFormData] = useState({
    full_name: profile.data && profile.data.full_name,
    phone_number: profile.data && profile.data.phone_number,
    address: profile.data && profile.data.address,
    id_number: profile.data && profile.data.id_number,
    npwp_number: profile.data && profile.data.npwp_number,
    country: profile.data && profile.data.country,
    file_ktp_url: profile.data && profile.data.file_ktp_url,
    file_npwp_url: profile.data && profile.data.file_npwp_url,
    image: profile.data && profile.data.image_url,
    // file_avatar_url: profile.data && profile.data.file_avatar_url,
  });

  const {
    full_name,
    phone_number,
    address,
    id_number,
    npwp_number,
    country,
    file_npwp_url,
    file_ktp_url,
    image,
  } = formData;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    fetchAPI();
  };

  const fetchAPI = async (val) => {
    const urlDev = process.env.REACT_APP_API_URL_DEV;
    const urlProd = process.env.REACT_APP_API_URL_PROD;
    const token = Cookies.get("jwt");

    const avatarData = {
      file_avatar: avatar,
    };

    const API_URL =
      process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;
    const path =
      val === "avatar" ? "profiles/upload-avatar" : "profiles/update";
    const datas = val === "avatar" ? avatarData : formData;

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        JWT: token,
      },
    };

    const response = await axios.post(API_URL + path, datas, config);

    if (!response.data.success) {
      setIsUpdateSuccess(false);
      setResMsg(response.data.msg);
      notify(response.data.msg, response.data.success);
    } else {
      setIsUpdateSuccess(true);
      setResMsg(response.data.msg);
      notify(response.data.msg, response.data.success);
      dispatch(getProfile());
    }
  };

  // upload avatar
  const onChangeAvatar = (e) => {
    const file = e.target.files[0];

    setAvatar(file);
    handleAvatarPrev(file);
  };

  const handleAvatarPrev = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setAvatarPrev(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangeAvatar = () => {
    console.log(avatar);

    fetchAPI("avatar");
    setAvatar(null);
    setAvatarPrev(null);
  };

  const cancelChangeAvatar = () => {
    setAvatar(null);
    setAvatarPrev(null);
  };

  const notify = (msg, type) => {
    if (type) {
      toast.success(msg);
    } else {
      toast.error(msg);
    }
  };

  const userProfilePic = profile.data && profile.data.image_url;
  const userFullName = profile.data && profile.data.full_name;
  const userEmail = user && user.email;

  // const [name, setName] = useState(userFullName);

  return (
    <>
      {user.success ? (
        <>
          <Nav />
          <div className="md:pl-[30vw] xl:pl-[20vw]">
            <BreadcrumbGoBack title={"Edit Profile"} />
            <div className="px-6 pb-32">
              <div className="md:px-8 my-8 flex flex-col flex-wrap h-[80px]">
                {/* <img className="w-[80px] h-[80px] border border-radius rounded-[50%] bg-black grow-3 shrink-0 basis-full" src="/images/profile.jpg" /> */}
                <div className="flex gap-3 items-end">
                  <div className="w-[80px] h-[80px] relative overflow-hidden">
                    {avatarPrev !== null ? (
                      <img
                        className="w-full h-full absolute object-cover border border-radius rounded-[50%] bg-black grow-3 shrink-0 basis-full"
                        src={avatarPrev}
                        alt="avatar"
                      />
                    ) : (
                      <img
                        className="w-full h-full absolute object-cover border border-radius rounded-[50%] bg-black grow-3 shrink-0 basis-full"
                        src={userProfilePic}
                        alt="avatar"
                      />
                    )}
                  </div>

                  {/* image uploader */}
                  {avatarPrev === null ? (
                    <div className="relative w-9 flex flex-col items-center">
                      <input
                        type="file"
                        onChange={onChangeAvatar}
                        className="absolute w-9 h-9 opacity-0"
                      />
                      <AiOutlineCloudUpload size={20} />
                      <span className="text-sm">Unggah</span>
                    </div>
                  ) : (
                    <div className="flex gap-2">
                      <button
                        onClick={handleChangeAvatar}
                        className="bg-btn-gold shadow-btn-shadow px-3 py-1 rounded-lg text-[11px]"
                      >
                        Simpan
                      </button>
                      <button
                        onClick={cancelChangeAvatar}
                        className="bg-white shadow-btn-shadow px-3 py-1 rounded-lg text-[11px]"
                      >
                        Batal
                      </button>
                    </div>
                  )}
                </div>
                {/* <span className="basis-[33.33%] shrink-0">ID</span> */}
                <span className="basis-[33.33%] shrink-0 font-semibold">
                  {userFullName && userFullName.length > 15
                    ? userFullName.slice(0, 15) + " ..."
                    : userFullName}
                </span>
                <span className="basis-[33.33%] shrink-0">
                  {userEmail && userEmail.length > 15
                    ? userEmail.slice(0, 15) + " ..."
                    : userEmail}
                </span>
              </div>
              <div className="md:px-8">
                <div className="mt-4 title">
                  <h3 className="text-base text-gray-400">Akun</h3>
                </div>
                {/* <div> */}
                <form onSubmit={onSubmit}>
                  <div className="mb-4 md:flex">
                    <div className="flex-auto border-b border-gray-100 py-2">
                      <h3 className="font-semibold">Nama Lengkap</h3>
                      <span className="text-base text-gray-400 mb-2">
                        Nama harus sesuai kartu identitas
                      </span>
                      <input
                        id="ProfileFullName"
                        name="full_name"
                        type="text"
                        value={full_name}
                        onChange={onChange}
                        className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                  </div>
                  <div className="mb-4 md:flex">
                    <div className="flex-auto border-b border-gray-100 py-2">
                      <h3 className="font-semibold">Alamat</h3>
                      <span className="text-base text-gray-400 mb-2">
                        Alamat
                      </span>
                      <textarea
                        id="ProfileAddress"
                        name="address"
                        type="text"
                        value={address}
                        placeholder="Place text here"
                        onChange={onChange}
                        className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                  </div>
                  <div className="mb-4 md:flex">
                    <div className="flex-1 border-b border-gray-100 py-2 md:pr-2">
                      <h3 className="font-semibold">Phone Number</h3>
                      <span className="text-base text-gray-400 mb-2">
                        Nomor telepon
                      </span>
                      <input
                        id="ProfilePhoneNumber"
                        name="phone_number"
                        type="text"
                        value={phone_number}
                        onChange={onChange}
                        className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="flex-1 border-b border-gray-100 py-2 md:pl-2">
                      <h3 className="font-semibold">NPWP Number</h3>
                      <span className="text-base text-gray-400 mb-2">
                        Nama harus sesuai kartu identitas
                      </span>
                      <input
                        id="ProfileNPWPNumber"
                        name="npwp_number"
                        type="text"
                        value={npwp_number}
                        onChange={onChange}
                        className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                  </div>
                  <div className="mb-4 md:flex">
                    <div className="flex-auto border-b border-gray-100 py-2 md:pr-2">
                      <h3 className="font-semibold">ID Number</h3>
                      <span className="text-base text-gray-400 mb-2">
                        Nama harus sesuai kartu identitas
                      </span>
                      <input
                        id="ProfileIDNumber"
                        name="id_number"
                        type="text"
                        value={id_number}
                        onChange={onChange}
                        className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="flex-auto border-b border-gray-100 py-2 md:pl-2">
                      <h3 className="font-semibold">NPWP Number</h3>
                      <span className="text-base text-gray-400 mb-2">
                        Nama harus sesuai kartu identitas
                      </span>
                      <input
                        id="ProfileNPWPNumber"
                        name="npwp_number"
                        type="text"
                        value={npwp_number}
                        onChange={onChange}
                        className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col items-center justify-between">
                    <button
                      className="w-full bg-[#FCCF08] hover:bg-blue-70 text-black font-bold py-2 px-4 mb-3 rounded-[34px] focus:outline-none focus:shadow-outline"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </form>
                {/* </div> */}
                <div className="py-2 flex justify-between items-center border-b border-gray-100">
                  <Link to={"/"}>
                    <span className="font-semibold">App Version</span>
                  </Link>
                  <span>1.0.0</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <NotLogin />
        </>
      )}

      <ToastContainer />
    </>
  );
}

export default ProfileUpdate;

import React, { useEffect } from "react";
import Header from "../../components/client/Header";
import Footer from "../../components/client/Footer";
import BlogHeader from "../../components/blog/BlogHeader";
import BlogMain from "../../components/blog/BlogMain";

function Blog() {
  useEffect(() => {
    document.title = "Xaurius | Blog";
  }, []);

  return (
    <>
      <Header />
      <BlogHeader />
      <BlogMain />
      <Footer />
    </>
  );
}

export default Blog;

import axios from "axios";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL = process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

// create buy
const createBuy = async (buyXAUData, token) => {
  const config = {
    headers: {
      JWT: token,
    },
  };
  console.log(buyXAUData, token, config);

  const response = await axios.post(API_URL + "buys/create", buyXAUData, config);

  return response.data;
};

const buyXAUService = {
  createBuy,
};

export default buyXAUService;

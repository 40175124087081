import React from "react";
import NotFound from "../../assets/images/404.png";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className="w-full h-screen bg-[#FCCF08] flex justify-center items-center">
      <div className="flex flex-col items-center gap-3 cursor-pointer">
        <img src={NotFound} alt="404" width="400" />
        <div
          onClick={() => {
            navigate(-1);
          }}
          className="flex items-center gap-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M10 19L3 12M3 12L10 5M3 12L21 12"
              stroke="#111827"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="text-lg font-semibold">Back</span>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import balanceIDRService from "./balanceIDRService";
import Cookies from "js-cookie";

const initialState = {
  message: "",
  balanceIDR: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
};

// get balance
export const getBalance = createAsyncThunk("balances", async (_, thunkAPI) => {
  // console.log(thunkAPI);
  try {
    const token = thunkAPI.getState().auth.token; // get token from auth state
    // console.log(token);
    return await balanceIDRService.getBalance(token);
  } catch (error) {
    // console.log(error);
    const message = (error.response && error.response.data && error.response.data.msg) || error.message || error.toString();

    if (error.response.status === 401) {
      Cookies.set("user", JSON.stringify(error.response.data), { expires: 2 });
    }

    return thunkAPI.rejectWithValue(message); // return axios error to message state
  }
});

export const balanceIDRSlice = createSlice({
  name: "balanceIDR",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBalance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = state.isSuccess === false ? true : false;
        state.isSuccess = action.payload.success;
        state.message = action.payload;
        state.balanceIDR = action.payload.data;
      })
      .addCase(getBalance.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.balanceIDR = null;
      });
  },
});

export const { reset } = balanceIDRSlice.actions;
export default balanceIDRSlice.reducer;

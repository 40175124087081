import axios from "axios";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL = process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

// get user profile
const lastPriceData = async () => {
  const response = await axios.get(API_URL + "charts/last-price");
  return response.data;
};


export default lastPriceData;
